import {useState, useEffect} from 'react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {Redirect} from 'react-router-dom'

import MonProfil from './monProfil/MonProfil'
import UpdateProfile from './updateProfile/UpdateProfile'

function Profil(){

	//auth
    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Beenonvi | Profil"
        return (()=>{
            document.title=""
        })
    },[]);

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            setAuth(false)
        }else{
            setAuth(true)
        }
        
    },[])

    if(auth){
        return (<Redirect to={'/login'} />)
    }

	return(
		<div className="admin">
            <div className="container">
                <div className="row">
                    <div className="admin__content col-lg-12 col-md-12 col-sm-12">
                    	<Tabs>
	                    	<TabList>
	                    		<Tab>Mon profil</Tab>
	                    		<Tab>Modifier mon profil</Tab>
                    		</TabList>
                    		<TabPanel>
                    			<MonProfil />
                    		</TabPanel>
                    		<TabPanel>
                    			<UpdateProfile />
                    		</TabPanel>
                    	</Tabs>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Profil