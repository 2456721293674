import {useState, useEffect} from 'react'
//axios
import axios from 'axios'//moment
import Moment from 'react-moment'
//loader
import Loader from 'react-loader-spinner'
//pagination
import Pagination from '../../../../includes/pagination/Pagination'

function Demande(props){

	const [loading, setLoading] = useState(false)

    //user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

    //request variable(demande)
	const [showRequest, setShowRequest] = useState([])

    //variables for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(8)

    //get all requests
	const getRequest = async (user_id) => {
        setLoading(true)
        const res = await axios.get(`https://admin.beenonvi.bj/api/v1/charging-requests/${user_id}`,{headers:{'Authorization': `Bearer ${token}`,}})
        setShowRequest(res.data.data)
        setLoading(false)
    }      

    useEffect(()=>{
        getRequest(user.id)
    },[])

    //pagination
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = showRequest.slice(indexOfFirstPost, indexOfLastPost)
    
    //function paginate
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return(
        <>
        <div className="admin__demande-list">
        	<table>
                <thead>
                    <th>References ID</th>
                    <th>Montant</th>
                    <th>Date/Heure de demande</th>
                    <th className="text-center">Status</th>
                </thead>
                <tbody>
                { !loading ? (
                    currentPosts.length == 0 ? (
                            <tr className="text-center">
                                <div
                                    style={{
                                        'padding': '50px',
                                        'fontSize': '14px',
                                        'position': 'sticky',
                                        'left': '50%',
                                        'transform': 'translateX(-50%)'
                                    }}
                                >Aucune demande effectuée</div>
                            </tr>
                        ):(
                            currentPosts.map((request) => (
                                <tr key={request.id}>
                                    <td>{request.transaction_id}</td>
                                    <td>{request.amount} FCFA</td>
                                    <td>{(request.updated_at).replace(/,/g, '-')}</td>
                                    <td>
                                        {
                                            request.status == false ? (
                                                <span className="wait">En attente</span>
                                            ) : (
                                                <span className="finish">Validée</span>
                                            )
                                        }
                                    </td>
                                </tr>
                            )))
                        ): (
                            <div 
                                className="text-center" 
                                style={{ 
                                        'padding':'10px 0',
                                        'transform': 'translateX(-50%)',
                                        'left': '50%',
                                        'position': 'sticky'
                                    }}
                                >
                                <Loader type="TailSpin" color="#f27f17" height={50} width={50}/>
                            </div>
                        )
                    }
                </tbody>
            </table>
        </div>
        <Pagination 
            postsPerPage={postsPerPage} 
            totalPosts={showRequest.length} 
            paginate={paginate} 
        />
        </>
    )


}
/*const mapStateToProps = ({session}) => ({
    user: session.user
})*/

export default Demande