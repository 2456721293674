import {useEffect} from 'react'
import Crumb from '../../includes/crumb/Crumb'
import FormContact from './formContact/FormContact'
import {Redirect} from  'react-router-dom'

import './Contact.scss'

function Contact(props) {

    useEffect(()=>{
        document.title="Beenonvi | Contact"
        return (()=>{
            document.title=""
        })
    },[]);

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (
        <main className="main">
            <Crumb pageRoute="/contacts" pageName="Contacts" />
            <div className="contact">
                <div className="container">
                    <h2 className="h2 mt-4 text-center">Contacts</h2>
                    <div className="row">
                        <div className="contact__content col-lg-12 col-md-12 col-sm-12">
                            <div className="contact__left">
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Cotonou&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                                        </iframe>
                                        <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">Friday Night Funkin Mods</a>
                                    </div>
                                </div>
                                <div className="contact__rs mt-3">
                                    <p>Nos reseaux sociaux</p>
                                    <div className="rs">
                                        <a href="#"><span className="mdi mdi-facebook"></span></a>
                                        <a href="#"><span className="mdi mdi-instagram"></span></a>
                                        <a href="#"><span className="mdi mdi-twitter"></span></a>
                                    </div>   
                                </div>
                            </div>
                            <div className="contact__right">
                                <p>Veuillez nous contacter via ce formulaire pour toute inquiétude</p>
                                <FormContact />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Contact;