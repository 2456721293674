import {useEffect} from 'react'
import Slider from "react-slick";

import {Redirect} from 'react-router-dom'

import FormSearchGare from './formSearchGare/FormSearchGare';

import './Home.scss';

import imageVoyage from '../../../../images/nv_banner_img1.png';
import way from '../../../../images/nv_banner_img2.png';
import line1 from '../../../../images/line_left.png';
import line2 from '../../../../images/line_right.png';
import phone from '../../../../images/phone.png';
import phoneApp from '../../../../images/google_play.png';
import roundLeft from '../../../../images/round_left.png';
import roundRight from '../../../../images/round_right.png';
import logoBlolab from '../../../../images/logo.jpg';
import partnerBg from '../../../../images/bg_partner.png';

function ServiceItem(props) {
    return (
        <div className="service__item">
            <div className="service__logo">
                <span className={`mdi ${props.icon}`}></span>
            </div>
            <h3 className="h3">{props.title}</h3>
            <p>{props.text}</p>
        </div>
    );
}

function Home(){

    const settings = {
        dots:false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        //prevArrow:'<span className="mdi mdi-chevron-left"></span>',
        //nextArrow:'<span className="mdi mdi-chevron-right"></span>', 
        responsive: [
            {
                breakpoint: 992,
                settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                }
            },
            {
                breakpoint: 420,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                }
            },
        ],
    
    }

    useEffect(()=>{
        document.title="Beenonvi | Acceuil"
        return (()=>{
            document.title=""
        })
    },[]);

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (

        
        <main className="main">

            {/* banner */}
            <div className="banner">
                <img className="banner__way" src={way} alt="way" />
                <div className="container">
                    <div className="row">
                        <div className="banner__content col-lg-12 col-md-12 col-sm-12">
                            <div className="banner__img">
                                <img src={imageVoyage} alt="image_de_voyage" />
                            </div>
                            <div className="banner__form">
                                <p>Rechercher votre itinéraire</p>
                                <FormSearchGare />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Nos services */}
            <div className="service">
                <div className="line line1">
                    <img src={line1} alt="line1"/>
                </div>
                <div className="line line2">
                    <img src={line2} alt="line2"/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="service__content col-lg-12 col-md-12 col-sm-12">
                            <h2 className="h2 text-center">Nos services</h2>
                            <div className="service__card">
                                <ServiceItem 
                                    icon="mdi-heart"
                                    title="Espace personnalisé"
                                    text="Enregistrer vos préférences et créer votre voyage idéale"
                                />
                                <ServiceItem 
                                    icon="mdi-id-card"
                                    title="Paiement simplifié"
                                    text="Enregistrer votre carte bancaire pour réserver et payer facilement"
                                />
                                <ServiceItem 
                                    icon="mdi-selection-ellipse"
                                    title="Vos réservations en un clic"
                                    text="Retrouvez à tout moment vos billets et votre historique"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* phone */}
            <div className="phone">
                <div className="round round1">
                    <img src={roundLeft} alt="round1"/>
                </div>
                <div className="round round2">
                    <img src={roundRight} alt="round2"/>
                </div>
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="phone__content col-lg-12 col-md-12 col-sm-12">
                                <div className="phone__img">
                                    <img src={phone} alt="phone_app" />
                                </div>
                                <div className="phone__text">
                                    <p>
                                        Facilitez-vous la vie en téléchargeant notre application mobile sur
                                    </p>
                                    <div className="phone__app">
                                        <img src={phoneApp} alt="phone_app"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* partner */}
            <div className="partner">
                <div className="partner__bg">
                    <img src={partnerBg} alt="bg_partner" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="partner__content col-lg-12 col-md-12 col-sm-12">
                            <h2 className="h2 text-center">Nos partenaires</h2>
                            <div className="partner__list">
                                <Slider {...settings}>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                    <div className="partner__item">
                                        <img src={logoBlolab} alt="logo_blolab"/>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </main>

    );
}

export default Home;