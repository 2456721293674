
import {NavLink, Redirect, useHistory} from "react-router-dom";
import {useState, useEffect} from 'react'
//axios
import axios from 'axios'
//import {useForm} from 'react-hook-form';

//formik
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'

//loader
import Loader from 'react-loader-spinner'

import {TextInput} from '../../includes/formLibs/FormLibs'

import Button from '../../includes/button/Button';

import logo from '../../../../images/nv_logo.png';
import imageBus from '../../../../images/image-bus.png';

function Login(props){

    const history = useHistory()
    //err
    const [err, setErr] = useState('')

    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Beenonvi | Login"
        return (()=>{
            document.title=""
        })
    },[]);


    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }


    return(
        <div className="log-reg">
            <div className="log-reg__content">
                <div className="log-reg__head">
                    {/*<NavLink to="/" className="log-reg__logo">
                        <img src={logo} alt="logo_nonvi_voyage"/>
                    </NavLink>
                    <NavLink to="/">
                        <span className="mdi mdi-arrow-left"></span>
                        Retour à l'accueil
                    </NavLink>*/}
                </div>
                <div className="log-reg__body">
                    <div className="log-reg__banner">
                        <div className="log-reg__img">
                            <img src={imageBus} alt="image_bus" />
                        </div>
                    </div>
                        
                    <div className="log-reg__form">
                    <Formik
                        initialValues ={{
                            device_name:'beenonvi',
                            phone:"",
                            password:""
                        }}

                        validationSchema = {
                            Yup.object({
                                device_name: Yup.string().required(),
                                phone: Yup.string().required('Telephone obligatoire'),
                                password: Yup.string().required('Mot de passe obligatoire')    
                            })
                        }

                        onSubmit = {(values, {setSubmitting, setFieldError}) => {
                            
                            axios.post("http://admin.beenonvi.bj/api/v1/login" , values,
                            {
                                headers : {
                                    'Accept': "application/json",
                                    'Content-Type': "application/json"
                                }
                            }).then((response) => {
                                const {data} = response
                                if(data.success === false){
                                    setErr(data.message)
                                    setSubmitting(false)
                                } else if(data.success === true){
                                    sessionStorage.setItem('token', data.token)
                                    sessionStorage.setItem('userData', JSON.stringify(data.user))
                                    window.location.reload()
                                    history.push('/user-admin')
                                }
                            }).catch(err => console.log(err))
                            
                        }}
                    >
                        
                        {({isSubmitting, handleChange}) => (
                            <Form className="form">
                                <p 
                                    className="mb-0 mt-0" 
                                    style={{
                                        'padding':`${err == "" ? null : "5px 10px"}`,
                                        'fontSize': "14px"
                                    }}>
                                    {err}
                                </p>
                                <TextInput
                                    type="text"
                                    name="device_name"
                                    hide="d-none"
                                />
                                <TextInput
                                    label="Téléphone"
                                    type="text"
                                    name="phone"
                                    placeholder="entrer votre numero"
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Mot de passe"
                                    type="password"
                                    name="password"
                                    placeholder="entrer votre mot de passe"
                                    onChange={handleChange}
                                />
                                <div className={`form__item ${isSubmitting && 'text-center'}`}>
                                    {!isSubmitting &&(
                                        <Button type="submit" text="Connexion"/>
                                    )}
                                    {isSubmitting &&(
                                        <Loader
                                            type="ThreeDots"
                                            color="orange"
                                            height="40px"
                                            width="60"
                                        />
                                    )}
                                </div>
                            </Form>

                        )}  
                        
                    </Formik>
                        <p>
                            <NavLink to="/resetpassword" className="link">
                                <span className="mdi mdi-chevron-double-left"></span>
                                Mot de passe oublié ?
                                <span className="mdi mdi-chevron-double-right"></span>
                            </NavLink>
                            <NavLink to="/register" className="link">
                                <span className="mdi mdi-chevron-double-left"></span>
                                Créer un compte
                                <span className="mdi mdi-chevron-double-right"></span>
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )


}
export default Login;