import {useState} from 'react'
//axios
import axios from 'axios'
//formik
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'
//moment
import moment from 'moment'

//loader
import Loader from 'react-loader-spinner'

import {TextInput, TextSelect} from '../../../../includes/formLibs/FormLibs'

import Button from '../../../../includes/button/Button';

import './UpdateProfil.scss'

function UpdateProfile(){

	//user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

	//err
    const [err, setErr] = useState('')

	return(
		<div className="update-profile">
			<Formik
                initialValues = {{
                    
                    lastname: user.lastname,
                    firstname: user.firstname,
                    profession: user.profession,
                    location: user.location,
                    phone: user.phone,
                    birthday: user.birthday,
                    email: user.email == null ? "" : user.email,
                    //password:"",
                }}

                validationSchema = {
                    Yup.object({
                        lastname: Yup.string(),
                        firstname: Yup.string(),
                        profession: Yup.string(),
                        location : Yup.string(),
                        phone: Yup.string(),
                        birthday: Yup.date(),
                        email: Yup.string().email('Email invalide'),
                        //password: Yup.string().required('Mot de passe obligatoire'),    
                    })
                }

                onSubmit = {(values, {setSubmitting, setFieldError}) => {
                    console.log(values)
                    axios.post("https://admin.beenonvi.bj/api/v1/profile/update" ,
                    	{	
                    		lastname: values.lastname,
                    		firstname: values.firstname,
                    		profession: values.profession,
                    		location: values.location,
                    		phone: values.phone,
                    		birthday: moment(values.birthday).format('DD/MM/YYYY'),
                    		email:values.email,
                    	},
	                    {
	                        headers : {
	                            'Authorization': `Bearer ${token}`,
	                            'Accept': "application/json",
	                            'Content-Type': "application/json"
	                        }
	                    }).then((response) => {
	                        const {data} = response
	                        if(data.success === false){
	                            setErr(data.message)
	                            console.log(data)
	                            setSubmitting(false)
	                        } else if(data.success === true){
	                            console.log(data)
	                            setErr(data.message)
	                            sessionStorage.setItem('userData', JSON.stringify(data.user))
	                            setSubmitting(false)
	                            window.location.reload()
	                        }
	                    }).catch(err => console.log(err))
	                    
                }}

            >
            {({isSubmitting, handleChange}) => (
                <Form className="form">
                    <p 
                        className="mb-3 mt-0" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                    <div className="form__grid">
                        <TextInput
                            label="Nom"
                            type="text"
                            name="lastname"
                            placeholder="Entrer votre nom"
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Prénom(s)"
                            type="text"
                            name="firstname"
                            placeholder="Entrer votre prénom(s)"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form__grid">
                         <TextInput
                            label="Profession"
                            type="text"
                            name="profession"
                            placeholder="Entrer votre profession"
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Adresse géographique"
                            type="text"
                            name="location"
                            placeholder="Entrer votre adresse"
                            onChange={handleChange}
                        />   
                    </div>
                    <TextInput
                        label="Date de naissance"
                        type="date"
                        name="birthday"
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Téléphone"
                        type="text"
                        name="phone"
                        placeholder="Entrer votre numéro"
                        onChange={handleChange}
                    />
                    <TextInput
                        label="E-mail"
                        type="email"
                        name="email"
                        placeholder="Entrer votre e-mail"
                        onChange={handleChange}
                    />
                    {/*<TextInput
                        label="Mot de passe"
                        type="password"
                        name="password"
                        placeholder="Entrer mot de passe"
                        onChange={handleChange}
                    />*/}
                    <div className={`form__item ${isSubmitting && 'text-center'}`}>
                        {!isSubmitting &&(
                             <Button type="submit" text="Modifier" />
                        )}
                        {isSubmitting &&(
                            <Loader
                                type="ThreeDots"
                                color="orange"
                                height="40px"
                                width="60"
                            />
                        )}
                    </div>
                    <p 
                        className="mb-1 mt-3" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                </Form>
            )}
                
            </Formik>
		</div>
	)
}
export default UpdateProfile