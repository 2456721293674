import {useState, useEffect} from 'react'
//axios
import axios from 'axios'
//loader
import Loader from 'react-loader-spinner'
//formik
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'

import {TextInput, TextSelect} from '../../../../includes/formLibs/FormLibs'
import Button from '../../../../includes/button/Button'


function Recharge(props){

    //err
    const [err, setErr] = useState('')
    
    //user info
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

    return(
    	<>
            <Formik
                initialValues ={{
                    userId: user.id,
                    amount:"",
                    transactionId:""
                }}
                validationSchema = {
                    Yup.object({
                        userId: Yup.number().integer().required(),
                        amount: Yup.string().required('Montant obligatoire'),
                        transactionId: Yup.string().required('ID transaction obligatoire')    
                    })
                }
    
                onSubmit = {(values, {setSubmitting, setFieldError}) => {
                    //console.log(values)
                    axios.post("http://admin.beenonvi.bj/api/v1/charging-account" , values,
                        {
                            headers : {
                                'Accept': "application/json",
                                'Content-Type': "application/json",
                                'Authorization': `Bearer ${token}`
                            }
                        }).then((response) => {
                            const {data} = response
                            if(data.success === false){
                                //console.log(data)
                                setErr(data.message)
                                setSubmitting(false)
                            } else if(data.success === true){
                                //console.log(data)
                                setErr(data.message)
                                setSubmitting(false)
                            }
                        }).catch(err => console.log(err)) 
                        
                }}
            
            >
                {({isSubmitting}) => (
                    <Form className="form">
                        <p 
                            className="mb-0 mt-0" 
                            style={{
                                'padding':`${err == "" ? null : "5px 10px"}`,
                                'fontSize': "14px"
                            }}>
                            {err}
                        </p>
                        <TextInput 
                            name="userId"
                            type="number"
                            hide="d-none"
                        />
                        <TextInput 
                            name="amount"
                            type="string"
                            label="Montant"
                            placeholder="entrer le montant"
                        />
                        <TextInput 
                            name="transactionId"
                            type="text"
                            label="ID de la transaction"
                            placeholder="entrer l'ID  de votre transaction"
                        />
                        <div className={`form__item ${isSubmitting && 'text-center'}`}>
                            {!isSubmitting &&(
                                <Button type="submit" text="Envoyer la demande"/>
                            )}
                            {isSubmitting &&(
                                <Loader
                                    type="ThreeDots"
                                    color="orange"
                                    height="40px"
                                    width="60"
                                />
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )


}
/*const mapStateToProps = ({session}) => ({
    user: session.user
})*/

export default Recharge;