import toast, { Toaster } from 'react-hot-toast';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import {useState, useEffect, useRef} from 'react'
//redirect
import {Redirect} from 'react-router-dom'

import NewticketForm from './newTicket/NewticketForm'
import Ticket from './ticket/Ticket'

//kendo react
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

//pagination
import Pagination from '../../../includes/pagination/Pagination'

import './OurTikets.scss'

function OurTickets(){
    //auth
    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Beenonvi | Mes tickets"
        return (()=>{
            document.title=""
        })
    },[]);

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            console.log('connecter')
        }else{
            setAuth(true)
        }
        
    },[])

    if(auth){
        return (<Redirect to={'/login'} />)
    }
        
    //download ticket
    function handleDownload(event) {
        let _id = event.currentTarget.getAttribute('data-id')
        let elem = document.getElementById(_id)
        savePDF(elem, { paperSize:  "A6" })
    };

    return(
        <div className="admin">
            <div className="container">
                <div className="row">
                    <div className="admin__content col-mg-12 col-md-12 col-sm-12">
                        <Tabs>
                            <TabList className="">
                                <Tab>Tous les tickets</Tab>
                                <Tab>Nouveau ticket</Tab>
                            </TabList>
                            <TabPanel>
                                {/*<h3 className="h3 text-center">Tous mes tickets</h3>*/}
                                <div className="admin__allTicket pt-4 mt-4 mb-5">
                                    <Ticket 
                                        onClickbtn={handleDownload}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                {/*<h3 className="h3 text-center">Nouveau ticket</h3>*/}
                                <div className="admin__newTicket">
                                    <NewticketForm />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default OurTickets