import {useState, useEffect} from 'react'
import Loader from "react-loader-spinner"


function Itineraires({lines, loading}) {
    return (
        <table>
            <thead>
                <th>Villes</th>
                <th>ITINÉRAIRES</th>
            </thead>
            <tbody>
                { !loading ? (

                    Object.keys(lines).map((index) => (
                        <tr key={index}>
                            <td>{index}</td>
                            <td>
                                <ul>
                                {
                                        lines[index].map(g => (
                                            <li key={g.id}>
                                                {g.label}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </td>
                        </tr>
                    )) ) : (
                        <div 
                            className="text-center" 
                            style={{ 
                                    'padding':'10px 0',
                                    'transform': 'translateX(-50%)',
                                    'left': '50%',
                                    'position': 'sticky'
                                }}
                            >
                            <Loader type="TailSpin" color="#f27f17" height={50} width={50}
                                style={{                                    
                                    "backgroundColor": "#f9f9f9f2"
                                }}
                            />
                        </div>
                    )
                }
            </tbody>
        </table>
    );
}

export default Itineraires;