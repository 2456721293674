import {useState, useEffect} from 'react'
import axios from 'axios'
import {Redirect} from 'react-router-dom'

import {Tab, Tabs, TabList, TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

//moment
import Moment from 'react-moment'

//loader
import Loader from 'react-loader-spinner'

import Recharge from './recharge/Recharge'
import Balance from './balance/Balance'
import Demande from './demande/Demande'
import Historique from './historique/Historique'


import './Portefeuille.scss'

function Portefeuille({user}){
    
    //auth
    const [auth, setAuth] = useState(false)

    //numero mobile money
    const [numMomo, setNumMomo] = useState([])

    useEffect(()=>{
        document.title="Beenonvi | Portefeuille"
        return (()=>{
            document.title=""
        })
    },[]);

    const getNumeroMobileMoney = async () => {
        const res = await axios.get('https://admin.beenonvi.bj/api/v1/parameter/numero-mobile-money')
        
        setNumMomo(res.data)
    }

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            console.log('connecter')
        }else{
            setAuth(true)
        }

        getNumeroMobileMoney()
        
    },[])

    if(auth){
        return (<Redirect to={'/login'} />)
    }

    return(
        <div className="admin">
            <div className="container">
                <div className="row">
                    <div className="admin__content col-lg-12 col-md-12 col-sm-12">
                        <Tabs>
                            <TabList>
                                <Tab>Ma balance</Tab>
                                <Tab>Recharge</Tab>
                                <Tab>Liste les demandes</Tab>
                                <Tab>Historique</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="admin__balance">
                                    <p>
                                        Balance actuelle
                                        <span className="d-block">
                                            <Balance />
                                        </span>
                                    </p>
                                    <span className="devise">fcfa</span>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="info_momo text-center">
                                    <p>
                                        Veuillez faire le transfert du montant  sur le {numMomo.value} avant de soumettre les
                                        informations ci-dessous.
                                    </p>
                                </div>
                                <div className="admin__newb">
                                    <Recharge />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="admin__demande">
                                    <Demande />
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="admin__historique">
                                    <Historique />
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default Portefeuille;