import {useState, useEffect} from 'react'
import axios from 'axios'
import {Redirect} from 'react-router-dom'

import Crumb from '../../includes/crumb/Crumb'
import InputSearch from '../../includes/inputSearch/InputSearch'
import Itineraires from './itineraires/Itineraires'


function NosItineraires(props) {

    const [lines, setlines] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        
        const fetchGares = async () => {
            setLoading(true)
            const res = await axios.get('https://admin.beenonvi.bj/api/v1/lines-list')
            setlines(res.data.data)
            setLoading(false);
        }

        fetchGares();
        
    },[])

    useEffect(()=>{
        document.title="Benonvi | Nos itinéraires"
        return (()=>{
            document.title=""
        })
    },[])

    const [auth, setAuth] = useState(false)

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (
        <main className="main">
            <Crumb pageRoute='/nos-itineraires' pageName="Nos itinéraires" />
            <div className="nos-gares nos-itineraires">
                <div className="nos-gares__map">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Cotonou&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">FNF Mods</a>
                        </div>
                    </div>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Porto-Novo&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">FNF Mods</a>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="nos-gares__content col-lg-12 col-md-12 col-sm-12">
                            <h2 className="h2 mt-4 text-center">Nos itinéraires</h2>
                            <div className="filter-g-i">
                                <InputSearch 
                                    type="search"
                                    placeholder="taper pour rechercher..."
                                />
                                <span className="mdi mdi-search-web"></span>
                            </div>
                            <div className="nos-gares__table ours">
                                <Itineraires lines={lines} loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default NosItineraires;