import Button from '../../../includes/button/Button';


function FormSearchGare(props) {

    return (
        <form className="form form-search-gare">
            <div className="form__item">
                <label htmlFor="Gare de depart">Gare de depart</label>
                <select name="gare_depart">
                    <option value="">--choisir gare de depart--</option>
                    <option value="">Cotonou</option>
                </select>
            </div>
            <div className="form__item">
                <label htmlFor="Gare d'arrivee">Gare d'arrivee</label>
                <select name="gare_arrivee">
                    <option value="">--choisir d'arrivee--</option>
                    <option value="">Cotonou</option>
                </select>
            </div>
            <div className="form__item">
                <label htmlFor="Gare de depart">Gare de depart</label>
                <input type="date" name="date_depart" />
            </div>
            <div className="form__item">
                <Button type="submit" text="Rechercher" />
            </div>
        </form>
    );
}

export default FormSearchGare;