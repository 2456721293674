import {useState, useEffect} from 'react'
import moment from 'moment'

import './MonProfil.scss'

function MonProfil(){
	//user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))
    //console.log(user)
	return(
		<div className="myprofil">
			<div className="myprofil__head">
				<div className="myprofil__img">
					<img src={user.profile_photo_url} alt="user_avatar"/>
				</div>
			</div>
			<div className="myprofil__info">
				<ul>
					<li>
						<span className="mdi mdi-account"></span>
						{user.fullname}
					</li>
					<li>
						<span className="mdi mdi-phone"></span>
						{user.phone}
					</li>
					<li>
						<span className="mdi mdi-calendar-account-outline"></span>
						{moment(user.birthday).format('DD/MM/YYYY')}
					</li>
					<li>
						<span className="mdi mdi-account-tie"></span>
						{user.profession}
					</li>
					<li>
						<span className="mdi mdi-warehouse"></span>
						{user.location}
					</li>
					<li>
						
						{user.email == null ? '' : (
							<>
								<span className="mdi mdi-email"></span>
								{user.email}
							</>
						)}
					</li>
				</ul>
			</div>
		</div>
	)
}
export default MonProfil