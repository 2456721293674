import {useEffect,useState} from 'react'
import {Redirect} from 'react-router-dom'

import Crumb from '../../includes/crumb/Crumb'

import bus from '../../../../images/bus.jpg'
import carImg from '../../../../images/car1.png'
import carImg1 from '../../../../images/t1.jpg'
import carImg2 from '../../../../images/t2.jpg'
import carImg3 from '../../../../images/t3.jpg'
import carImg4 from '../../../../images/t4.webp'

import './Apropos.scss';

function Apropos(props) {

    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Beenonvi | A propos"
        return (()=>{
            document.title=""
        })
    },[]);

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (
        <main className="main">
            <Crumb pageRoute='/a-propos' pageName="A propos" />

            {/* nous */}
            <div className="about-ours">
                <div className="car"><img src={carImg} alt="car" /></div>
                <div className="container">
                    <div className="row">
                        <div className="about-ours__content col-lg-12 col-md-12 col-sm-12 ">
                            <h2 className="h2 text-center">Qui sommes-nous ?</h2>
                            <div className="about-ours__card">
                                <div className="about-ours__img">
                                    <img src={bus} alt="a-propos_de_nous"/>
                                </div>
                                <div className="about-ours__text">
                                    <p>
                                        Nonvi Voyage Plus est une compagnie qui vous offre un service unique
                                        de transport inter-urbain au Bénin, à bord de bus climatisés avec
                                        accès à internet et la possibilité de charger votre smartphone. Nonvi
                                        Voyage Plus, voyager est un plaisir.
                                    </p>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* equipe */}
            <div className="about-equipe">
                <div className="container">
                    <div className="row">
                        <div className="about-equipe__content col-lg-12 col-md-12 col-sm-12">
                            <h2 className="h2 text-center">Notre équipe</h2>
                            <div className="about-equipe__list">
                                <div className="about-equipe__item text-center">
                                    <div className="about-equipe__img">
                                        <img src={carImg1} alt="a-propos_de_nous"/>
                                    </div>
                                    <h3 className="h3 mt-3">Equipe 1</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem</p>
                                </div>
                                <div className="about-equipe__item text-center">
                                    <div className="about-equipe__img">
                                        <img src={carImg2} alt="a-propos_de_nous"/>
                                    </div>
                                    <h3 className="h3 mt-3">Equipe 2</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem</p>
                                </div>
                                <div className="about-equipe__item text-center">
                                    <div className="about-equipe__img">
                                        <img src={carImg3} alt="a-propos_de_nous"/>
                                    </div>
                                    <h3 className="h3 mt-3">Equipe 3</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem</p>
                                </div>
                                <div className="about-equipe__item text-center">
                                    <div className="about-equipe__img">
                                        <img src={carImg4} alt="a-propos_de_nous"/>
                                    </div>
                                    <h3 className="h3 mt-3">Equipe 4</h3>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* politique */}
            <div className="about-ours about-politique">
                <div className="container">
                    <div className="row">
                        <div className="about-ours__content col-lg-12 col-md-12 col-sm-12 ">
                            <h2 className="h2 text-center">Notre politique</h2>
                            <div className="about-ours__card">
                                <div className="about-ours__img">
                                    <img src={bus} alt="a-propos_de_nous"/>
                                </div>
                                <div className="about-ours__text">
                                    <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem labore. 
                                        Rem accusamus dolores cupiditate doloremque sequi, explicabo cumque nostrum animi iure 
                                        rerum quas error nihil quo saepe laborum. rerum quas error nihil quo saepe laborum Lorem 
                                        ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem labore. Rem accusamus 
                                        dolores cupiditate doloremque sequi, explicabo cumque nostrum animi iure rerum quas error 
                                        nihil quo saepe laborum. rerum quas error nihil quo saepe laborum Lorem ipsum dolor sit 
                                        amet consectetur adipisicing elit.

                                    </p>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
    );
}

export default Apropos;