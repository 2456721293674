
import { NavLink} from "react-router-dom";

import './LinkButton.scss';

function LinkButton(props){


    return(
        <NavLink to={props.route}>{props.text}</NavLink>
    )
}

export default LinkButton;