//import React from 'react';
import {useState, useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"

//layouts
import Header from './website/component/layouts/header/Header'
import Footer from './website/component/layouts/footer/Footer'

//scroll to top
import ScrollToTop from './website/component/includes/scrollToTop/scrollToTop'

//pages
import Home from './website/component/views/home/Home'
import Contact from './website/component/views/contact/Contact'
import Login  from './website/component/views/login/Login'
import Register  from './website/component/views/register/Register'
import Apropos  from './website/component/views/apropos/Apropos'
import NosItineraires  from './website/component/views/nosItineraires/NosItineraires'
import NosGares  from './website/component/views/nosGares/NosGares'
import MentionsLegales  from './website/component/views/mentionsLegales/MentionsLegales'
import UseConditions from './website/component/views/useConditions/UseConditions'
import ResetPassword from './website/component/views/resetPassword/ResetPassword'

//Dashboard
import UserAdmin from './website/component/views/userAdmin/UserAdmin'
import Portefeuille from './website/component/views/userAdmin/portefeuille/Portefeuille'
import OurTickets from './website/component/views/userAdmin/ourTickets/OurTickets'
import Profil from './website/component/views/userAdmin/profil/Profil'
import Colis from './website/component/views/userAdmin/colis/Colis'

//style
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

function App() {
  
  //header
  const [btnClick, setBtnClick ] = useState(false);
  const [menuMobile, setMenuMobile] = useState(false);

  //page name
  const [locat, setLocation] = useState('');
  
  function handleBtnMenuClick(){
    setBtnClick(!btnClick);
    setMenuMobile(!menuMobile);
  }

  /*function handleSetLocation(){
    let url = (window.location.pathname.split('/')[1]==='home') ? 'home' : window.location.pathname.split('/')[1]
    console.log(url);
    setLocation(url);
  }*/
  //alert(window.location.pathname)
  useEffect(()=>{
    //setLocation(window.location.pathname.split('/')[1])
    //console.log(window.location.pathname)

  },[])
  if(window.location.pathname == '/login'){
      console.log('login')
    } else{
      console.log('other')
    }

  return (

    <Router>
        <div className={`wrapper ${locat}`} >

          <Header 
            btnClick={handleBtnMenuClick} 
            menuMobileToggle={menuMobile ? 'active' : ''}
            menuOnClik={handleBtnMenuClick}
            toggleClasse={btnClick ? 'active' : ''}
          />
          <ScrollToTop/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/nos-itineraires" component={NosItineraires} />
            <Route path="/nos-gares" component={NosGares} />
            <Route path="/contacts" component={Contact} />
            <Route path="/a-propos" component={Apropos} />
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/mentions-legales" component={MentionsLegales} />
            <Route path="/use-conditions" component={UseConditions} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Switch>
              <Route path="/user-admin" component={UserAdmin} />
              <Route path="/mes-tickets" component={OurTickets} />
              <Route path="/portefeuille" component={Portefeuille} />
              <Route path="/profil" component={Profil} />
              <Route path="/colis" component={Colis} />
            </Switch>

            <Route path="*"><main className="main">404</main></Route>
          </Switch>
          
          {
            window.location.pathname !== '/login' || 
            window.location.pathname !== '/register' ? (<Footer />) : null
          }
        </div>
    </Router>

  );
}

export default App;
