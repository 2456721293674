import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import QRCode from "react-qr-code"
import Moment from 'react-moment'
import moment from 'moment'

//loader
import Loader from 'react-loader-spinner'
//pagination
import Pagination from '../../../../includes/pagination/Pagination'
//redirect
import {Redirect} from 'react-router-dom'

//logo
import logoWrite from '../../../../../../images/logo_ecriture.png'

function Ticket({onClickbtn}){

	const [loading, setLoading] = useState(false)

    const  [tickets, setTickets] = useState([])

    //user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
	const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(6)

    const getTicket = async (user_id) => {
        setLoading(true)
        const res = await axios.get(`https://admin.beenonvi.bj/api/v1/my-tickets/${user_id}`,{headers:{'Authorization': `Bearer ${token}`,}})
        setTickets(res.data.data)
        setLoading(false)
    }

    useEffect(()=>{
    	if(sessionStorage.length == 0 ){
            return (<Redirect to={'/login'} />)
        }else{
            getTicket(user.id)
        }
        
    },[])

    //pagination
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = tickets.slice(indexOfFirstPost, indexOfLastPost)

    //function paginate
    const paginate = (pageNumber) => setCurrentPage(pageNumber)    

	
	return(
		<>
		{!loading ? (
			currentPosts.length == 0 ? (
                <div className="empty-fetch">Aucun ticket réservé</div>
			):(
				currentPosts.map((ticket) => (
					<div className="ticket" key={ticket.id} id={ticket.id} data-id={ticket.id} >
						<div className="ticket__content">
							<div className="ticket__enterprise mb-3">
								<div className="ticket__enterpriseLogo text-center">
									<img src={logoWrite} alt="logo_beenonvi"/>
									<span>Voyage  Plus</span>
								</div>
								<div className="ticket__enterpriseInfo text-center">
									<span>RCCM: RBCOT/19 B24519</span>
									<span>IFU: 3201910787083</span>
									<span>Tel: (+229) 69-34-33-33 </span>
								</div>
							</div>
							<div className="ticket__head">
							{
								ticket.is_already_used == 0 ? (
									<span className="ticket__statu invalide">
										<span>En cours</span>
									</span>
								) : (
									<span className="ticket__statu valide">
										<span>Deja consomme</span>
									</span>
								)
							}
								
								<span className="ticket__bus">
									{ticket.car.name}, Siège {ticket.seat.number}

								</span>
							</div>
							<div className="ticket__body">
								<h5>
									Ticket {ticket.code}<span className="mdi mdi-ticket-account"></span>
									<span className="fullname">{user.fullname}</span>
								</h5>
								<div className="ticket__info">
									<ul>
										<li>
											Départ
											<span className="d-block">
												{ticket.line.start_station.name}
											</span>
										</li>
										<li>
											Arrivée
											<span className="d-block">
												{ticket.line.end_station.name}
											</span>
										</li>
										<li>
											Date de voyage
											<span className="d-block">
												
												{moment(ticket.departure_date).format('DD/MM/YYYY')}
											</span>
										</li>
										<li>
											Heure de voyage
											<span className="d-block">
												{ticket.departure_time}
											</span>
										</li>
										<li>
											Date d'achat du ticket
											<span className="d-block">
												<Moment format="DD/MM/YYYY, HH:mm">{ticket.created_at}</Moment>
											</span>
										</li>
									</ul>
									<button 
										className="ticket__button"
										data-id={ticket.id}
										data-ticket={ticket.code}
										data-name={user.fullname}
										data-startStation={ticket.line.start_station.name}
										data-endStation={ticket.line.end_station.name}
										data-date={moment(ticket.departure_date).format('DD/MM/YYYY')}
										data-time={ticket.departure_time}
										data-seat={ticket.seat.number}
										onClick={onClickbtn}
									>
										<span className="mdi mdi-download"></span>
									</button>
									<div className="ticket__qrCode">
										<QRCode 
											size="50" 
											value={ticket.code} 
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			))):(
				<div className="text-center" 
                    style={{ 
                            'padding':'10px 0',
                            'position':'absolute',
                            'top':'60px',
                            'left':'50%',
                            'transform':'translateX(-50%)'
                        }}
                    >
                    <Loader type="TailSpin" color="#f27f17" height={80} width={80}/>
                </div>
				)
			}
			<div className="ticket-pagination">
                <Pagination 
                    postsPerPage={postsPerPage} 
                    totalPosts={tickets.length} 
                    paginate={paginate} 
                />
            </div>
		</>
	)
}
export default Ticket