import {useState, useEffect, useRef} from 'react'
//axios
import axios from 'axios'
//react router dom
import {Link} from 'react-router-dom'
//toast
import toast, { Toaster } from 'react-hot-toast';
//moment
import moment from 'moment'
//formik and yup
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'
//loader
import Loader from 'react-loader-spinner'

//jquery
import $ from "jquery"

//component
import {TextInput, TextSelect} from '../../../../includes/formLibs/FormLibs'
import Button from '../../../../includes/button/Button' 

//images
import bus from '../../../../../../images/bus.png';

function NewticketForm(){
    const [carloading, setCarLoading] = useState(false)
    const [seatLoading, setSeatLoading] = useState(false)

    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))
    const data_token = sessionStorage.getItem('token')
    const [token, setToken] = useState(data_token)

    //station
    const [stationsStart, setStationsStart] = useState([])
    const [stationsEnd, setStationsEnd] = useState([])
    
    //categorie bus
    const [categories, setCategories] = useState([])

    //bus availables
    const [busAvailable, setBusAvailable] = useState([])

    //seats
    const [seats, setSeats] = useState([]);

    //err
    const [err, setErr] = useState('')

    /***********************************************************
    ***********************************************************/

    const [dateStart , setDateStart] = useState('')
    const [timeStart , setTimeStart] = useState('')
    const [hourStart , setHourStart] = useState('')
    const [lineId, setLIneId] = useState('')
    const [carId, setCarId] = useState('')
    const [price, setPrice] = useState('')
    const [departureDate, setDepartureDate] = useState('')

    /**********************************************************
    **********************************************************/

    //get sations start
    useEffect(()=>{
        
        const getStations = async () => {
            const res = await axios.get('https://admin.beenonvi.bj/api/v1/stations', {headers : {'Authorization': `Bearer ${token}`}})
            setStationsStart(res.data) 
            setStationsEnd(res.data)          
        }
        getStations();
        
    },[])
     

    //get categories
    useEffect(()=>{

        const getCategories = async () => {
            const res = await axios.get('https://admin.beenonvi.bj/api/v1/categories', {headers : {'Authorization': `Bearer ${token}`}})
            setCategories(res.data)
        }
        getCategories()

    }, [])

    //get cars
    const getCars = async (start_stat, end_stat, dep_date, type_ca ) => {
        setCarLoading(true)
        const res_car = await axios.post(`https://admin.beenonvi.bj/api/v1/find-cars?start_station=${start_stat}&end_station=${end_stat}&departure_date=${moment(dep_date).format('DD/MM/YYYY')}&car_type=${type_ca}`,{userId:user.id} ,{headers : {'Authorization': `Bearer ${token}`}})
        let datas = res_car.data.data
        setBusAvailable(datas)
        setCarLoading(false)
        /*if(busAvailable.length == 0){
            setSeats([])
        }*/
        

    } 
    //console.log(busAvailable) 

    //get value onclick field
    function getValue(){
             
        let start_station = document.getElementById('start_station').value
        let end_station = document.getElementById('end_station').value
        let car_type= document.getElementById('car_id').value
        let departure_date = document.getElementById('departure_date').value
        
        
        //setDepartureDate(moment(departure_date).format('DD/MM/YYYY'))
        setDepartureDate(departure_date)
        //setDateStart(start_station)

        if(start_station !== '' && end_station !== '' && departureDate !== "" && car_type !== ""){
            getCars(start_station, end_station, departureDate, car_type)
        }

    }

    useEffect(()=>{
        //getCars();
    },[])


    //get availables seats
    const getSeat = async (carId, line_id, time) => {
        setSeatLoading(true)
        const res_seat = await axios.get(`https://admin.beenonvi.bj/api/v1/car-seats/${carId}/${line_id}/${departureDate}/${time}`,{headers : {'Authorization': `Bearer ${token}`}})
        setSeats(res_seat.data.data)
        setSeatLoading(false)
        //console.log(res_seat.data.data)
    }

    const getBusLineIdCarId = (event) => {
        event.preventDefault()
        let car_Id = event.currentTarget.getAttribute('data-car')
        let line_Id = event.currentTarget.getAttribute('data-line')
        let time = event.currentTarget.getAttribute('data-time')
        let price = event.currentTarget.getAttribute('data-price')
        setCarId(car_Id)
        setLIneId(line_Id)
        setPrice(price)
        setTimeStart(time)

        //console.log(car_Id+","+line_Id)

        //execute function getSeat()
        getSeat(car_Id, line_Id, time)
        
    } 
    useEffect(()=>{
        getSeat();
    },[])   

    /*const getHour = () => {
        let hrs = document.getElementById('hour').value
        setHourStart(hrs)
        //busAvailable.map(car => {
        //    Object.keys(car.start_times).map(h => console.log(h))
        //})
        const getBus = [...busAvailable].map(car => {
            Object.keys(car.start_times).map(h => console.log(h == hourStart))
        })
        //const getBus = [...busAvailable].map(car => {
        //    Object.keys(car.start_times).map(t => console.log(car))
        //})
        
        console.log(getBus)
        //setBusAvailable(getBus);

        //console.log(busAvailable)
    }*/
    /*$(document).ready(function(){
        $("#d-hour").on("keyup", function() {
            var value = $(this).val().toLowerCase();
            $("#bus__available a.bus__item span.bus__info ").filter(function() {
                $(this).toggle($('span.bus__time').text().toLowerCase().indexOf(value) > -1)
            });
        });
    });*/

    
    return(
        <Formik

            //initiales value
            initialValues ={{
                user_id: user.id,
                //car_id:carId,
                start_station:"",
                end_station:"",
                departure_date:"",
                //departure_time: timeStart,
                seat_id:""
            }}

            //validate values
            validationSchema = {
                Yup.object({
                    user_id: Yup.string().required(),
                    start_station: Yup.string().required(),
                    end_station: Yup.string().required(),
                    car_id:Yup.string().required(),
                    departure_date: Yup.date().required(),
                    //departure_time: Yup.string().required(),
                    seat_id: Yup.number().integer() 
                })
            }

            //submit values
            onSubmit = {(values, {setSubmitting, setFieldError}) => {
                console.log(
                    'user_id:'+values.user_id+
                    ', car_id:'+carId+
                    ', line_id:'+lineId+
                    ', departure_date:'+values.departure_date+
                    ', departure_time:'+timeStart+
                    ', seat_id:'+values.seat_id+
                    ', price:'+price
                )
                
                axios.post("https://admin.beenonvi.bj/api/v1/pay-ticket" , 
                    {
                        user_id:values.user_id,
                        car_id:values.car_id,
                        line_id:lineId,
                        departure_time:timeStart,
                        departure_date:departureDate,
                        seat_id:values.seat_id,
                        price:price
                    },
                    {
                        headers : {
                            'Authorization': `Bearer ${token}`,
                            'Accept': "application/json",
                            'Content-Type': "application/json"
                        }
                    }).then((response) => {
                        const {data} = response
                        if(data.success === false){
                            console.log(data)
                            toast(data.message)
                            setErr(data.message)
                        } else if(data.success === true){
                            console.log(data)
                            toast(data.message)
                            setErr(data.message)
                            setSubmitting(false)
                        }
                    }).catch(err => console.log(err))
                    
            }}
        >
            {({isSubmitting, handleChange}) => (
                <>
                <Form className="form">
                    <p 
                        className="mb-3 mt-0" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                    <TextSelect onClick={getValue}  name="start_station" as="select" label="Gare de départ" id="start_station">
                        <option>--choisir gare de depart</option>
                        {
                            stationsStart.map((stationS) => (
                                <option key={stationS.id} value={stationS.id}>{stationS.name}</option>
                            ))
                        }
                    </TextSelect>
                    <TextSelect onClick={getValue} name="end_station" as="select" label="Gare d'arrivée" id="end_station">
                        <option>--choisir gare d'arrivée</option>
                        {
                            stationsEnd.map((stationE) => (
                                <option key={stationE.id} value={stationE.id}>{stationE.name}</option>
                            ))
                        }
                    </TextSelect>
                    <TextInput 
                        name="departure_date"
                        type="date"
                        label="Date de départ"
                        id="departure_date"
                        onClick={getValue}
                    />
                    <TextInput 
                        name="departure_time"
                        type="time"
                        label="Heure"
                        id="departure_dtime"
                        onClick={getValue}
                        hide="d-none"
                    />
                    <TextSelect onClick={getValue} name="car_id" as="select" label="Type de bus" id="car_id">
                        <option>--choisir type de bus--</option>
                        {
                            categories.map((categorie) => (
                                <option value={categorie.id}>{categorie.name}</option>
                            ))
                        }
                    </TextSelect>
                    <div className="form__item">
                        <div className="d-flex align-items-center justify-content-between">
                            <label htmlFor="start_date">Bus disponibles</label>
                            {/*<div className="filter-time d-flex align-items-center">
                                Filtrer{hourStart}
                                <input 
                                    type="time" 
                                    name="d-hour" 
                                    id="d-hour"
                                    value={hourStart}
                                    onChange={event => setHourStart(event.target.value)}
                                />
                            </div>*/}
                        </div>
                        <div className="bus__available" id="bus__available">
                            {!carloading ? (
                                busAvailable.map((car , index) => (
                                    Object.keys(car.start_times).map((val) => (
                                        
                                        <Link 
                                            to='#' 
                                            id={`${car.id}${val}`} 
                                            data-id={`${car.id}${val}`}
                                            data-line={car.pivot.line_id} 
                                            data-car={car.pivot.car_id} 
                                            data-time={val}
                                            data-price={car.pivot.price}
                                            key={`${car.id}${val}`} 
                                            className="bus__item" 
                                            onClick={getBusLineIdCarId}
                                        >
                                            <span className="bus_line">{car.pivot.line_id}</span>
                                            <span className="bus_car">{car.pivot.car_id}</span>
                                            <span className="bus__info">
                                                <span className="bus__time">
                                                    <span>Départ</span>
                                                    <time className="bus__time-start">{val}</time>
                                                </span>
                                                <span className="bus__placetotal">
                                                    <span>Place totale</span>
                                                    <span>{car.number_of_places}</span>
                                                </span>
                                                <span className="bus__placeRest">
                                                    <span>Place restante</span>
                                                    <span>{car.number_of_places - car.start_times[val]}</span>
                                                </span>
                                                <span className="bus__prix">
                                                    <span>Prix</span>
                                                    <span>{car.pivot.price} FCFA</span>
                                                </span>
                                            </span>
                                            <span className="bus__img">
                                                <span className="bus__numMat">{car.matriculation}</span>
                                                <span className="bus__v">
                                                    <img src={bus} alt="bus" />
                                                </span>
                                            </span>
                                        </Link>
                                    ))
                                ))): (
                                    <div className="text-center" 
                                        style={{ 
                                                'padding':'10px 0',
                                                'transform': 'translateX(-50%)',
                                                'left': '50%',
                                                'position': 'sticky'
                                            }}
                                        >
                                        <Loader type="TailSpin" color="#f27f17" height={50} width={50} 
                                            style={{                                    
                                                "backgroundColor": "#f9f9f9f2"
                                            }}
                                        />
                                    </div>
                                )

                            }
                        </div>
                    </div>

                    <div className="form__item seat-available">
                        <label htmlFor="Place disponible">Sieges disponibles</label>
                        <div className="seat-available__list">
                            {!seatLoading ? (
                                seats.map((seat => (
                                    <div className="seat-available__item" key={seat.id}>
                                        <div className="seat-available__box">
                                            <span className="seat-available__position">{seat.number}</span>
                                            <span className="seat-available__desc">Premiere place avant a cote de la fenetre</span>
                                            {
                                                seat.tickets.length == 0 ? (
                                                    <>
                                                        <span className="seat-available__statu">Disponible</span>
                                                        <input 
                                                            type="radio" 
                                                            value={seat.number} 
                                                            name="seat_id"
                                                            onChange={handleChange}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span className="seat-available__statu seat-available__statu-disabled">Occupé</span>
                                                        <input type="radio" value={seat.number} name="seat_id" disabled/>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                )))): (
                                    <div className="text-center" 
                                        style={{ 
                                                'padding':'10px 0',
                                                'transform': 'translateX(-50%)',
                                                'left': '50%',
                                                'position': 'sticky'
                                            }}
                                        >
                                        <Loader type="TailSpin" color="#f27f17" height={50} width={50} 
                                            style={{                                    
                                                "backgroundColor": "#f9f9f9f2"
                                            }}
                                        />
                                    </div>
                                )
                            }
                                
                            
                        </div>
                    </div>
                    <div className={`form__item ${isSubmitting && 'text-center'}`}>
                        {!isSubmitting &&(
                            <Button type="submit" text="Payerle ticket"/>
                        )}
                        {isSubmitting &&(
                            <Loader
                                type="ThreeDots"
                                color="orange"
                                height="40px"
                                width="60"
                            />
                        )}
                    </div>
                    <p 
                        className="mb-3 mt-0" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                </Form>
                <Toaster
                    containerStyle={{
                        top: 20,
                        right: 20
                    }}
                />
                </>
            )}
        </Formik>
    )
}

export default NewticketForm
