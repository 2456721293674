import {NavLink} from "react-router-dom"
import {useState, useEffect} from 'react'

import './Footer.scss';

function Footer(){
    //auth
    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            setAuth(false)
        }else{
            setAuth(true)
        }
        
    },[])

    //get current year
    function getCurrentYear(){
        return new Date().getFullYear()
    }

    return(
        <>
        { auth && (
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="footer-top__content col-lg-12 col-md-12 col-sm-12">
                                <ul className="footer-top__menu menu">
                                    <li className="menu__item">
                                        <NavLink exact to="/" activeClassName="active"><span className="mdi mdi-menu-right"></span>Accueil</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/nos-itineraires"><span className="mdi mdi-menu-right"></span>Nos itinéraires</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/a-propos"><span className="mdi mdi-menu-right"></span>Apropos</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/nos-gares"><span className="mdi mdi-menu-right"></span>Nos gares</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/use-conditions"><span className="mdi mdi-menu-right"></span>Conditions d'utilisation</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/mentions-legales"><span className="mdi mdi-menu-right"></span>Mentions légales</NavLink>
                                    </li>
                                </ul>
                                <div className="footer-top__follow">
                                    <p>Suivez nous sur:</p>
                                    <div className="rs">
                                        <a href="#"><span className="mdi mdi-facebook"></span></a>
                                        <a href="#"><span className="mdi mdi-instagram"></span></a>
                                        <a href="#"><span className="mdi mdi-twitter"></span></a>
                                    </div>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="footer-bottom__content col-lg-12 col-md-12 col-sm-12 text-center">
                                Copyright {getCurrentYear()} nonvi-voyage. Réalisé par <a href="https://www.blolab.org" target="_blank">Blolab</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            )
        }
        </>

    )

}

export default Footer;