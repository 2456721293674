import styled from 'styled-components'

export const StyledTextInput = styled.input`
    background-color:#fff;
`;

export const StyledLabel = styled.label`
    font-size:14px;
    margin-bottom:3px;
`;

export const ErrorMsg = styled.span`
    font-weight:400;
`;