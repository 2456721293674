import {useState, useEffect} from 'react'
//axios
import axios from 'axios'

//moment
import Moment from 'react-moment'
//loader
import Loader from 'react-loader-spinner'

import {Redirect} from 'react-router-dom'

function Balance(props){

	const [loading, setLoading] = useState(false)
    const [solde, setSolde] = useState([])

    //user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))
	
    const getSolde = async (user_id) =>{
        setLoading(true)
        const res = await axios.get(`https://admin.beenonvi.bj/api/v1/account/${user_id}`,{headers:{'Authorization': `Bearer ${token}`,}})
        setSolde(res.data.data[0])
        setLoading(false)
    }
    
    useEffect(()=>{
        if(sessionStorage.length == 0 ){
            return (<Redirect to={'/login'} />)
        }else{
            getSolde(user.id)
        }
    },[])
    
    return(
    	<>
            {!loading ? solde.balance : 
                (
                    <div 
                        
                        style={{ 
                                'padding':'10px 0',
                            }}
                        >
                        <Loader type="TailSpin" color="#f27f17" height={50} width={50} timeout={10000}/>
                    </div>
                )

            }
        </>
    )


}
/*const mapStateToProps = ({session}) => ({
    user: session.user
})*/

export default Balance;