import {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'

import Crumb from '../../includes/crumb/Crumb'

import './UseConditions.scss'



function UseConditions(props) {

    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Benonvi | Conditions d'utilisations"
        return (()=>{
            document.title=""
        })
    },[])

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (
        <main className="main">
            <Crumb pageRoute='/use-conditions' pageName="Conditions d'utilisations" />
            <div className="use-conditions b-text">
                <div className="bg-text">
                    <div className="box">
                        <div className="inner">
                            <span>Conditions d'utilisation</span>
                        </div>
                        <div className="inner">
                            <span>Conditions d'utilisation</span>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="b-text__content col-lg-12 col-md-12 col-sm-12">
                            {/* <h2 className="h2 text-center">Conditions d'utilisation</h2> */}
                            <div className="b-text__card">
                                <p>
                                    Afin de pouvoir bénéficier des produits et services de Nonvi Voyage Plus, le Voyageur doit prendre connaissance des présentes conditions générales de vente . Celles-ci sont accessibles sur le Site Nonvi Voyage Plus.
L’offre de transport Nonvi Voyage Plus et les services associés sont régis par les présentes Conditions Générales.
Nonvi Voyage Plus se réserve le droit de modifier les Conditions Générales à tout moment, étant entendu que de telles modifications seront inapplicables aux réservations préalablement acceptées par Nonvi Voyage Plus.
                                </p>
                                <h4 className="h4 text-uppercase"><span>1.</span> Identification</h4>
                                <p>
                                    Pour utiliser le service, tout voyageur doit s'inscrire avec mentions de ses informations personnelles basiques que sont : nom, prénom, numéro de téléphone, adresse ,email, sex,profession, date de naissance, etc...
                                </p>
                                <h4 className="h4 text-uppercase"><span>2.</span> Définitions</h4>
                                <p>
                                    <strong>Acheteur</strong> : Toute personne effectuant une réservation de Billet sur beenonvi. Lorsque l’acheteur n’est pas le voyageur, il effectue la réservation au nom et pour le compte du ou des Voyageurs.
                                </p>
                                <p>
                                    <strong>Application beenonvi</strong> : Application téléchargeable sur Play Store et Google Play permettant notamment d’effectuer une réservation, de consulter sa réservation ou de télécharger ses billets.
                                </p>
                                <p>
                                    <strong>Billet beenonvi</strong> : Le billet beenonvi peut au choix du voyageur prendre la forme soit d’un billet au format PDF à imprimer, soit d’un billet téléchargeable sur l’application beenonvi qui restera accessible hors-connexion. À noter que les billets au format PDF non imprimés par le client ou non téléchargés sur l’Application peuvent être refusés à l’accueil embarquement et faire l’objet d'un refus.
                                </p>
                                <p>
                                    <strong>Centre de Relation Client</strong> : Centre dédié à la Relation Client. Pour les questions liées au suivi de réservation, le Centre de Relation Client est joignable soit par le lien « Contact » du Site www.beenonvi.bj soit par email contact@beenonvi.com.
                                </p>
                                <p>
                                    <strong>Impression</strong> : Impression du billet beenonvi sur support papier.
                                </p>
                                <p>
                                    <strong>Mobile</strong> : Terminal téléphonique permettant d’utiliser l’application beenonvi et d’accéder à la plateforme beenonvi. L’application beenonvi peut aussi être utilisée sur une tablette
                                </p>
                                <p>
                                    <strong>Numéro de Réservation</strong> : Référence d’une réservation associée à un ou des trajets, communiquée à l’acheteur. Elle permet d’y apporter d’éventuelles modifications, d’effectuer l’impression du Billet beenonvi, et de télécharger son billet sur l’application beenonvi. Elle peut être demandée pour contacter le Centre de Relation Client beenonvi. Chaque réservation est identifiée par un numéro de réservation unique.
                                </p>
                                <p>
                                    <strong>Réservation</strong> : Opération d’achat et de paiement en ligne de Places sur le site beenonvi et sur notre application mobile. Le paiement s’effectue par MTN Mobile Money ou Moov Money sur la plateforme beenonvi ou via l'application mobile. La réservation est finalisée lors de la validation du paiement
                                </p>
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem labore. 
                                    Rem accusamus dolores cupiditate doloremque sequi, explicabo cumque nostrum animi iure 
                                    rerum quas error nihil quo saepe laborum. rerum quas error nihil quo saepe laborum Lorem 
                                    ipsum dolor sit amet consectetur adipisicing elit. Incidunt itaque rem labore. Rem accusamus 
                                    dolores.
                                </p>
                                <p>
                                    <strong>Chef de Bord</strong> : Ils assurent la sécurité à bord des bus et participent aux opérations de contrôle des billets beenonvi. Les Chefs de Bord sont assermentés et agréés.Les Chefs de bord sont également chargés de veiller au respect des règles de vie en gare et à bord des bus.
                                </p>
                                <p>
                                    <strong>Trajet</strong> : Voyage simple entre une gare de départ et une gare d’arrivée
                                </p>
                                <p>
                                <strong>Voyageur</strong> : Toute personne détentrice d’un billet beenonvi qui effectue un trajet dans un bus .</p>
                                <h4 className="h4 text-uppercase"><span>4.</span> Contrat de transport</h4>
                                <p>
                                   <strong> Définition du contrat de transport</strong>: L’acceptation des présentes conditions générales de vente par l’acheteur vaut acceptation de ces mêmes conditions par le ou les voyageurs composants la réservation.
                                </p>
                                <h4 className="h4 text-uppercase"><span>4.</span> Réservation et délivrance du Billet beenonvi</h4>
                                <p>
                                    Le billet est nominatif (nom, prénom, téléphone) et personnel (un billet par voyageur). Toute revente est strictement interdite et peut donner lieu à des poursuites judiciaires. La délivrance du billet est subordonnée au paiement de la réservation.
                                </p>
                                <h4 className="h4 text-uppercase"><span>5.</span>
                                    Envoi, impression et téléchargement du billet sur l’application
                                </h4>
                                <p>
                                    <strong>Delivrance</strong>: Le billet beenonvi est délivré une fois le paiement effectué et est téléchargeable depuis la plateforme.
À chaque Trajet correspond un Billet: il n’y a donc pas d’aller-retour sur un même Billet.
                                </p>
                                <p>
                                    <strong>Impression</strong>
                                    Le billet beenonvi doit être correctement Imprimé pour être valable.
Le Billet est valable uniquement s'il est imprimé sur du papier A4 blanc, vierge recto et verso, sans modification de la taille d'impression. Il ne peut y avoir qu'un seul billet imprimé à la fois par feuille de papier A4 (pas de recto verso).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </main>
    );
}

export default UseConditions;