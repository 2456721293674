import {useState, useEffect} from 'react'
import {NavLink, Redirect ,useHistory} from "react-router-dom";

//axios
import axios from 'axios'
//formik
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'

//loader
import Loader from 'react-loader-spinner'

import {TextInput, TextSelect} from '../../includes/formLibs/FormLibs'

import Button from '../../includes/button/Button';

import logo from '../../../../images/nv_logo.png';
import imageBus from '../../../../images/image-bus.png';

function Register(props){

    const history = useHistory()

    //err
    const [err, setErr] = useState('')

    useEffect(()=>{
        document.title = 'Beenonvi | Register'
        return(()=>{
            document.title = null
        })
    },[])

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }



    return(
        <div className="log-reg">
            <div className="log-reg__content">
                <div className="log-reg__head">
                    {/*<NavLink to="/" className="log-reg__logo">
                        <img src={logo} alt="logo_nonvi_voyage"/>
                    </NavLink>
                    <NavLink to="/">
                        <span className="mdi mdi-arrow-left"></span>
                        Retour à l'accueil
                    </NavLink>*/}
                </div>
                <div className="log-reg__body">
                    <div className="log-reg__banner">
                        <div className="log-reg__img">
                            <img src={imageBus} alt="image_bus" />
                        </div>
                    </div>
                    <div className="log-reg__form">
                    <Formik
                        initialValues ={{
                            lastname:"",
                            firstname:"",
                            profession:"",
                            location:"",
                            phone:"",
                            sex:"",
                            birthday:"",
                            email:"",
                            password:"",
                            confirmPassword:"",
                        }}

                        validationSchema = {
                            Yup.object({
                                lastname: Yup.string().required('Nom obligatoire'),
                                firstname: Yup.string().required('Prénom obligatoire'),
                                profession: Yup.string().required('Profession obligatoire'),
                                location : Yup.string().required('Adresse obligatoire'),
                                phone: Yup.string().required('Telephone obligatoire'),
                                sex: Yup.string().required('Sexe obligatoire'),
                                birthday: Yup.date().required("Date d'aniversaire obligatoire"),
                                email: Yup.string().email('Email invalide'),
                                password: Yup.string().required('Mot de passe obligatoire'),
                                confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Mot de passe non conforme')    
                            })
                        }

                        onSubmit = {(values, {setSubmitting, setFieldError}) => {
                            //console.log(values)
                            axios.post("https://admin.beenonvi.bj/api/v1/register" , values,
                            {
                                headers : {
                                    'Accept': "application/json",
                                    'Content-Type': "application/json"
                                }
                            }).then((response) => {
                                const {data} = response
                                if(data.success === false){
                                    console.log(data)
                                    setErr(data.errors[0])
                                    setSubmitting(false)
                                } else if(data.success == 'true'){
                                    console.log(data)
                                    history.push('/login')
                                }
                            }).catch(err => console.log(err))
                        }}

                    >
                    {({isSubmitting, handleChange}) => (
                        <Form className="form">
                            <p 
                                className="mb-3 mt-0" 
                                style={{
                                    'padding':`${err == "" ? null : "5px 10px"}`,
                                    'fontSize': "14px"
                                }}
                            >
                                {err}
                            </p>
                            <div className="form__grid">
                                <TextInput
                                    label="Nom"
                                    type="text"
                                    name="lastname"
                                    placeholder="entrer votre nom"
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Prénom(s)"
                                    type="text"
                                    name="firstname"
                                    placeholder="entrer votre prénom(s)"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form__grid">
                                 <TextInput
                                    label="Profession"
                                    type="text"
                                    name="profession"
                                    placeholder="entrer votre profession"
                                    onChange={handleChange}
                                />
                                <TextInput
                                    label="Adresse géographique"
                                    type="text"
                                    name="location"
                                    placeholder="entrer votre adresse"
                                    onChange={handleChange}
                                />   
                            </div>
                            <TextInput
                                label="Date de naissance"
                                type="date"
                                name="birthday"
                                onChange={handleChange}
                            />
                            <div className="form__grid mt-3">
                                <TextInput
                                    label="Téléphone"
                                    type="text"
                                    name="phone"
                                    placeholder="entrer votre numéro"
                                    onChange={handleChange}
                                />
                                <TextSelect name="sex" onChange={handleChange} label="Sex">
                                    <option >--choisissez votre sex--</option>
                                    <option value="Masculin">Masculin</option>
                                    <option value="Féminin">Féminin</option>
                                </TextSelect>
                            </div>
                            <TextInput
                                label="E-mail (facultatif)"
                                type="email"
                                name="email"
                                placeholder="entrer votre e-mail"
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Mot de passe"
                                type="password"
                                name="password"
                                placeholder="entrer mot de passe"
                                onChange={handleChange}
                            />
                            <TextInput
                                label="Confirmer mot de passe"
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirmer mot de passe"
                                onChange={handleChange}
                            />
                            <div className={`form__item ${isSubmitting && 'text-center'}`}>
                                {!isSubmitting &&(
                                     <Button type="submit" text="S'enregistrer" />
                                )}
                                {isSubmitting &&(
                                    <Loader
                                        type="ThreeDots"
                                        color="orange"
                                        height="40px"
                                        width="60"
                                    />
                                )}
                            </div>
                            <p 
                                className="mb-1 mt-3" 
                                style={{
                                    'padding':`${err == "" ? null : "5px 10px"}`,
                                    'fontSize': "14px"
                                }}
                            >
                                {err}
                            </p>
                        </Form>
                    )}
                        
                    </Formik>
                        <p style={{'textAlign':'right', 'display':'block', 'marginTop':'10px'}}>
                            <NavLink to="/login"  className="link">
                                <span className="mdi mdi-chevron-double-left"></span>
                                Se connecter
                                <span className="mdi mdi-chevron-double-right"></span>
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )


}
export default Register;