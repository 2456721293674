import {useState} from 'react'
//axios
import axios from 'axios'
//formik
import {Formik, Form , useFormikContext} from 'formik'
import * as Yup from 'yup'
//moment
import moment from 'moment'

//loader
import Loader from 'react-loader-spinner'
//range
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';

import {TextInput} from '../../../../includes/formLibs/FormLibs'

import Button from '../../../../includes/button/Button';

import './SendColis.scss'

const Input = styled(MuiInput)`
  width: 42px;
`;

function SendColis(){

    //user infos
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

    //is sensitive
    const [sensitive, setSensitive] = useState(false)
    const handleChangeSwitch = (event) => {
        setSensitive(event.target.checked);
        //console.log(sensitive)
    };

    //err
    const [err, setErr] = useState('')

    //weight
    const [value, setValue] = useState(0)

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? '' : Number(event.target.value));
    };
    const handleBlur = () => {
        if (value < 0) {
            setValue(0);
        } else if (value > 100) {
            setValue(100);
        }
    };

    
    return(
        <div className="send-colis">
            <Formik
                initialValues = {{
                    recipient_name: '',
                    recipient_phone: '',
                    send_town: '',
                    destination_town: '',
                    weight: value,
                    description: '',
                    send_date: '',
                    is_sensitive:'',
                }}

                validationSchema = {
                    Yup.object({
                        recipient_name: Yup.string().required('Nom du destinataire obligatoire'),
                        recipient_phone: Yup.string().required('Téléphone obligatoire'),
                        send_town: Yup.string().required('Ville d\'envoi obligatoire'),
                        destination_town : Yup.string().required('Ville de destination obligatoire'),
                        weight: Yup.string(),
                        description: Yup.string().required('Description obligatoire'),
                        send_date: Yup.date().required('Date d\'envois obligatoire'),    
                    })
                }

                onSubmit = {(values, {setSubmitting, setFieldError}) => {
                    console.log(values)
                    console.log(sensitive)
                    axios.post("https://admin.beenonvi.bj/api/v1/send-package" ,
                        {
                            description: values.description,
                            is_sensitive: sensitive,
                            weight: values.weight,
                            destination_town: values.destination_town,
                            send_town: values.send_town,
                            send_date: moment(values.send_date).format('DD/MM/YYYY'),
                            recipient_name: values.recipient_name,
                            recipient_phone: values.recipient_phone,
                            user_id: user.id
                        },
                        {
                            headers : {
                                'Authorization': `Bearer ${token}`,
                                'Accept': "application/json",
                                'Content-Type': "application/json"
                            }
                        }).then((response) => {
                            const {data} = response
                            if(data.success === false){
                                console.log(data.message)
                                setErr(data.message)
                                setSubmitting(false)
                            } else if(data.success === true){
                                console.log(data.message)
                                setErr(data.message)
                                setSubmitting(false)
                               // window.location.reload()
                            }
                        }).catch(err => console.log(err))
                        
                }}

            >
            {({isSubmitting, handleChange}) => (
                <Form className="form">
                    <p 
                        className="mb-3 mt-0" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                    <div className="form__grid">
                        <TextInput
                            label="Nom du destinataire"
                            type="text"
                            name="recipient_name"
                            placeholder="entrer le destinataire"
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Téléphone du destinataire"
                            type="text"
                            name="recipient_phone"
                            placeholder="entrer le téléphone"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form__grid">
                         <TextInput
                            label="Ville d'envoi"
                            type="text"
                            name="send_town"
                            placeholder="entrer ville d'envoi"
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Ville de réception"
                            type="text"
                            name="destination_town"
                            placeholder="entrer ville réception"
                            onChange={handleChange}
                        />   
                    </div>
                    <div className="form__item">
                        <label htmlFor="weight">Poids du colis</label>
                        <div>
                            <Box sx={{ width: '100%' }}>
                              <Grid container spacing={4} alignItems="center">
                                <Grid item xs>
                                  <Slider
                                    value={typeof value === 'number' ? value : 0}
                                    onChange={handleSliderChange}
                                    aria-labelledby="input-slider"
                                  />
                                </Grid>
                                <Grid item>
                                  <Input
                                    value={value}
                                    size="small"
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                      step: 0,
                                      min: 0,
                                      max: 100,
                                      type: 'number',
                                      'aria-labelledby': 'input-slider',
                                    }}
                                  />
                                  Kg
                                </Grid>
                              </Grid>
                            </Box>
                        </div>          
                    </div>
                    <div className="form__item send-colie">
                        <label htmlFor="is_sensitive">Colis Sensible</label>
                        <Switch
                          checked={sensitive}
                          onChange={handleChangeSwitch}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div className="form__item">
                        <label htmlFor="description">Description du colis</label>
                        <textarea 
                            name="description" 
                            placeholder="description"
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <TextInput
                        label="Date départ"
                        type="date"
                        name="send_date"
                        onChange={handleChange}
                    />
                    <div className={`form__item ${isSubmitting && 'text-center'}`}>
                        {!isSubmitting &&(
                             <Button type="submit" text="Envoyer" />
                        )}
                        {isSubmitting &&(
                            <Loader
                                type="ThreeDots"
                                color="orange"
                                height="40px"
                                width="60"
                            />
                        )}
                    </div>
                    <p 
                        className="mb-1 mt-3" 
                        style={{
                            'padding':`${err == "" ? null : "5px 10px"}`,
                            'fontSize': "14px"
                        }}
                    >
                        {err}
                    </p>
                </Form>
            )}
                
            </Formik>
        </div>
    )
}

export default SendColis