import {useState, useEffect} from 'react'
import Loader from "react-loader-spinner";
import axios from 'axios'
import {Redirect} from 'react-router-dom'

import Crumb from '../../includes/crumb/Crumb'
import InputSearch from '../../includes/inputSearch/InputSearch'
import Gares from './gares/Gares'

import './NosGares.scss'

function NosGares(){
    const [auth, setAuth] = useState(false)
    
    const [gares, setGares] = useState([])
    const [loading, setLoading] = useState(false)
    // const [filteredData, setFilteredData] = useState(gares)

    useEffect(()=>{
        
        const fetchGares = async () => {
            setLoading(true)
            const res = await axios.get('https://admin.beenonvi.bj/api/v1/stations-town-list')
            setGares(res.data.data) 
            setLoading(false);
        }

        fetchGares();
        
    },[])

    useEffect(()=>{
        document.title="Beenonvi | Nos gares"
        return (()=>{
            document.title=""
        })
    },[]);


    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return(
        <main className="main">
            <Crumb pageRoute='/nos-gares' pageName="Nos gares" />
            <div className="nos-gares">
                <div className="nos-gares__map">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Cotonou&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">FNF Mods</a>
                        </div>
                    </div>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Porto-Novo&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                            <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">FNF Mods</a>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="nos-gares__content col-lg-12 col-md-12 col-sm-12">
                            <h2 className="h2 mt-4 text-center">Nos gares</h2>
                            <div className="filter-g-i">
                                <InputSearch 
                                    type="search"
                                    placeholder="taper pour rechercher..."
                                    // onChange={(e)=>handleSearch(e)}
                                />
                                <span className="mdi mdi-search-web"></span>
                            </div>
                            <div className="nos-gares__table ours">

                                <Gares gares={gares} loading={loading} />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );

}

export default NosGares;