import {useField} from "formik"
import styled from 'styled-components'

import {StyledTextInput, StyledLabel, ErrorMsg} from '../styles/Styles'

export const TextInput = ({...props}) => {
    const [field, meta] = useField(props)

    return(
        <div className={`form__item ${props.hide}`}>
            <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
            <StyledTextInput {...field} {...props} />
            {meta.touched && meta.error ? (
                <ErrorMsg>{meta.error}</ErrorMsg>
            ) : (
                <ErrorMsg style={{"display":"none"}}>.</ErrorMsg>
            )}
        </div>
    )
}

export const SelectText = styled.select`

`;

export const TextSelect = ({...props}) => {
    const [field, meta] = useField(props)

    return(
        <div className="form__item">
            <StyledLabel htmlFor={props.name}>{props.label}</StyledLabel>
            <SelectText {...field} {...props}>
                {props.children}
            </SelectText>
            {meta.touched && meta.error ? (
                <ErrorMsg>{meta.error}</ErrorMsg>
            ) : (
                <ErrorMsg style={{"display":"none"}}>.</ErrorMsg>
            )}
        </div>
    )
}