
import {NavLink, Link, useHistory} from "react-router-dom";
import {useState, useEffect} from 'react'
import LinkButton from '../../includes/linkbutton/LinkButton';
import axios from 'axios'
import $ from 'jquery'
import './Header.scss';

import logo from '../../../../images/nv_logo.png';

function Header(props, {onClick, dropdown}){

    const [fixedHeader, setFixedHeader] = useState(false)

    const history = useHistory()

    const [auth, setAuth] = useState(false)

    //get user connected and her token
    const [token, setToken] = useState('')
    const [user, setUser] = useState([])
    
    //dropdown
    const [showDropdown, setShowDropdown] = useState(false)
    

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            setAuth(false)
        }else{
            setAuth(true)
        }

        //get properties of user connected
        if(sessionStorage.length !== 0){
            const _token = sessionStorage.getItem('token')
            const data = sessionStorage.getItem('userData')
            setToken(_token)
            setUser(JSON.parse(data))
        }
        
    },[])

    useEffect(() => {
        $(window).scroll(function(){
            if($(window).scrollTop() > 80){
                setFixedHeader(true)
            }else{
                setFixedHeader(false)
            }
        })
    },[])

    $(document).on("click", function(event){
        if(!$(event.target).closest(".ad-head__action").length){
            setShowDropdown(false)
        }
    });
    

    //logout function
    function handleClickLogout(e){
        e.preventDefault()
        setShowDropdown(!showDropdown)
        axios.post("https://admin.beenonvi.bj/api/v1/logout" ,{userId:user.id},
            {
                headers:{
                    'Authorization': `Bearer ${token}`,
                }
            }).then((response) => {
                const {data} = response
                if(data.success === false){
                    console.log(data.message)
                }else if(data.success === true){
                    console.log(data.message)
                    sessionStorage.removeItem('token', '')
                    sessionStorage.removeItem('userData', '')
                    sessionStorage.clear()
                    history.push('/')
                    window.location.reload()
                }
                
            }).catch(err => console.log(err))
    }

    return(
        <>
        {auth ? (
            <header className={`header ${!fixedHeader ? '' : 'fixed'}`}>
                <div className="container">
                    <div className="row">
                        <div className="header__content col-lg-12 col-md-12 col-sm-12">
                            <NavLink to="/" className="header__logo">
                                <img src={logo} alt="logo_non_voyage" />
                            </NavLink>
                            <div className={`header__center ${props.menuMobileToggle}`}>
                                <ul className="menu">
                                    <li className="menu__item active">
                                        <NavLink exact to="/" activeClassName="active" onClick={props.menuOnClik}>Accueil</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/nos-itineraires" onClick={props.menuOnClik}>Nos itinéraires</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/nos-gares" onClick={props.menuOnClik}>Nos gares</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/a-propos" onClick={props.menuOnClik}>A propos</NavLink>
                                    </li>
                                    <li className="menu__item">
                                        <NavLink to="/contacts" onClick={props.menuOnClik}>Contacts</NavLink>
                                    </li>
                                    <li className="menu__item log-reg-action">
                                        <NavLink to="/register" onClick={props.menuOnClik}>Créer un compte</NavLink>
                                    </li>
                                    <li className="menu__item log-reg-action">
                                        <NavLink to="/login" onClick={props.menuOnClik}>Se connecter</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="header__right">
                                <LinkButton route="register" text="Créer un compte" />
                                <LinkButton route="login" text="Se connecter" />
                            </div>
                            <a href="#" className="header__btnMenu" onClick={props.btnClick}>
                                <span className={props.toggleClasse}></span>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        ):(
            <>
                <head className= "ad-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="ad-head__content">                      
                                    <Link to="/user-admin" className="ad-head__img">
                                        <img src={logo} alt="logo__beenonvi" />
                                    </Link>
                                    <div className="ad-head__action">
                                        <a 
                                            href="#" 
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setShowDropdown(!showDropdown)
                                            }}
                                        >
                                            <span>
                                                <img src={user.profile_photo_url} alt="user_avatar" />
                                            </span>
                                            <span className="mdi mdi-chevron-down"></span>
                                        </a>
                                        <div className={`dropdown ${!showDropdown ? '' : 'active'}`}>
                                            <Link to="#" onClick={!auth && handleClickLogout} className="logout">Déconnexion</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </head>
                <Link to="/user-admin" id="btn-home">
                    <span className="mdi mdi-home-account"></span>
                </Link>
            </>
        )}
        </>
    )
}

export default Header;