import {NavLink} from 'react-router-dom'

import './Crumb.scss'


function Crumb(props) {
    return (
        <nav className="crumb">
            <div className="container">
                <div className="row">
                    <div className="crumb__content col-lg-12 col-md-12 col-sm-12">
                        <ul className="crumb__list">
                            <li className="crumb__item"><NavLink to="/">Acceuil</NavLink></li>
                            <li className="crumb__item"><NavLink to={props.pageRoute}>{props.pageName}</NavLink></li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Crumb;