
import './InputSearch.scss'

function InputSearch(props) {
    
    return (
        <input 
            onChange={props.onChange}
            type={props.type} 
            placeholder={props.placeholder}
            className="input-search" 
        />
    );
}

export default InputSearch;