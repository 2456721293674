import Button from '../../../includes/button/Button'

import './FormContact.scss'

function FormContact(props) {
    return (
        <form className="form">
            <div className="form__item">
                <label htmlFor="Name">Nom et prénom</label>
                <input type="text" name="name" placeholder="Nom et prénoms" />
            </div>
            <div className="form__item">
                <label htmlFor="Telephone">Téléphone</label>
                <input type="text" name="telephone" placeholder="Téléphone" />
            </div>
            <div className="form__item">
                <label htmlFor="Objet">Objet</label>
                <input type="text" name="objet" placeholder="Objet" />
            </div>
            <div className="form__item">
                <label htmlFor="Message" className="d-block">Message</label>
                <textarea type="text" name="message" placeholder="Message" />
            </div>
            <div className="form__item">
                <Button type="submit" text="Envoyer" />
            </div>
        </form>
    );
}

export default FormContact;