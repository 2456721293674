import {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import AdminHeader from '../../includes/adminHeader/AdminHeader'
import SideBar from '../../includes/sideBar/SideBar'

import './UserAdmin.scss'

import {useHistory, Redirect} from 'react-router-dom'

function UserAdmin() {
    
    const [auth, setAuth] = useState(false)
    
    useEffect(()=>{
        document.title="Beenonvi | Admin client"
        return (()=>{
            document.title=""
        })
    },[]);

    useEffect(()=>{
        if(sessionStorage.getItem('token')){
            console.log('connecter')
        }else{
            setAuth(true)
        }
    },[])

    if(auth){
        return (<Redirect to={'/login'} />)
    }
        
    return(
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">  
                        <div className="admin__menuList pb-4">
                            <Link to="/portefeuille">
                                <span className="mdi mdi-currency-usd-circle-outline"></span>
                                <span>Portefeuille</span>
                            </Link>
                            <Link to="/mes-tickets">
                                <span className="mdi mdi-id-card"></span>
                                <span>Mes tickets</span>
                            </Link>
                            <Link to="/colis">
                                <span className="mdi mdi-package-variant"></span>
                                <span>Envois de colis</span>
                            </Link>
                            <Link to="/profil">
                                <span className="mdi mdi-account"></span>
                                <span>Mon profil</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        
    );
}

export default UserAdmin