import {useEffect,useState} from 'react'
import {Redirect} from 'react-router-dom'

import Crumb from '../../includes/crumb/Crumb'

import './MentionsLegales.scss'

function MentionsLegales(props) {

    const [auth, setAuth] = useState(false)

    useEffect(()=>{
        document.title="Beenonvi | Mentions legales"
        return (()=>{
            document.title=""
        })
    },[]);

    if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/user-admin'} />)
    }

    return (
        <main className="main">
            <Crumb pageRoute='/mentions-legales' pageName="Mentions légales" />
            <div className="m-legales">
                <div className="bg-text">
                    <div className="box">
                        <div className="inner">
                            <span>Mentions légales</span>
                        </div>
                        <div className="inner">
                            <span>Mentions légales</span>
                        </div>
                    </div>
                </div>
                <div className="container mt-text">
                    <div className="row">
                        <div className="m-text__content col-lg-12 col-md-12 col-sm-12">
                            <div className="m-text__card">
                                <p><strong>Nonvi Voyage PLus</strong> est un service de la société <strong>NONVI SAS</strong></p>
                                <p>
                                    <span>1.</span>
                                    Adresse: Ave Van Vollenhoven, Cotonou 00229 Cotonou, Bénin
                                </p>
                                <p>
                                    <span>2.</span>
                                    Téléphone: (+229) 69-34-33-33
                                </p>
                                <p>
                                    <span>3.</span>
                                    Email: contact@beenonvi.bj
                                </p>
                                <p>
                                    <span>4.</span>
                                    Enregistrée au registre du commerce sous le numéro : RCCM RBOT/19 B2419
                                </p>
                                <p>
                                    <span>5.</span>
                                    Numéro individuel d’identification fiscale : 3201910787083
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
}

export default MentionsLegales;