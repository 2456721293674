import {useState, useEffect} from 'react'
//axios
import axios from 'axios'
//moment
import Moment from 'react-moment'
//loader
import Loader from 'react-loader-spinner'
//pagination
import Pagination from '../../../../includes/pagination/Pagination'

function Historique(props){

	const [loading, setLoading] = useState(false)

    //user info
    const _token = sessionStorage.getItem('token')
    const [token, setToken] = useState(_token)
    const data = sessionStorage.getItem('userData')
    const [user, setUser] = useState(JSON.parse(data))

    //hystorique variable
	const [historique, setHistorique] = useState([])

    //variables for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(15)

	const getAccount = async (user_id) => {
        setLoading(true)
        const res = await axios.get(`https://admin.beenonvi.bj/api/v1/account/${user_id}`,{headers:{'Authorization': `Bearer ${token}`,}})
        setHistorique(res.data.data[0].account_histories)
        setLoading(false)
    }

    useEffect(()=>{
        getAccount(user.id)
    },[])

    //pagination
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = historique.slice(indexOfFirstPost, indexOfLastPost)
    //function paginate
    const paginate = (pageNumber) => setCurrentPage(pageNumber)
    
    return(
        <>
        <div className="admin__historique-list">
        	<table>
                <thead>
                    <th>#</th>
                    <th>Montant</th>
                    <th>Date de transaction</th>
                    <th>Type de transaction</th>
                </thead>
                <tbody>
                {!loading ? (
                        currentPosts.length == 0 ? (
                            <tr className="text-center">
                                <div
                                    style={{
                                        'padding': '50px',
                                        'fontSize': '14px',
                                        'position': 'sticky',
                                        'left': '50%',
                                        'transform': 'translateX(-50%)'
                                    }}
                                >Votre historique est vide</div>
                            </tr>
                        ):(
                            currentPosts.map(h => (
                                <tr key={h.id}>
                                    <td>{h.id}</td>
                                    <td>{h.amount} FCFA</td>
                                    <td>
                                        <Moment format="DD/MM/YYYY HH:mm">{h.created_at}</Moment>
                                    </td>
                                    <td>
                                        {
                                            h.transaction_type == 'credit' ? (
                                                <span className="credit">
                                                    credit
                                                    <span className="mdi mdi-arrow-down"></span>
                                                </span>
                                            ) : (
                                                <span className="debit"> 
                                                    debit
                                                    <span className="mdi mdi-arrow-up"></span>
                                                </span>
                                            )
                                        }
                                    </td>
                                </tr>
                            )))
                        ) : (
                            <div 
                                className="text-center" 
                                style={{ 
                                        'padding':'10px 0',
                                        'transform': 'translateX(-50%)',
                                        'left': '50%',
                                        'position': 'sticky'
                                    }}
                                >
                                <Loader type="TailSpin" color="#f27f17" height={50} width={50}/>
                            </div>
                        )
                    }
                </tbody>
            </table>
        </div>
        <Pagination 
            postsPerPage={postsPerPage} 
            totalPosts={historique.length} 
            paginate={paginate} 
        />
        </>
    )


}
/*const mapStateToProps = ({session}) => ({
    user: session.user
})*/

export default Historique;
